import React, { createContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'

const google_apikey = 'AIzaSyCsb9rsA5JDiIYNfVaKJJKRKtiJ7zoewbw'

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

const setSession = (
    accessToken,
    refreshToken,
    expiresIn,
    full_access = false
) => {
    console.log('New Session')
    if (accessToken) {
        console.log('Login')
        localStorage.setItem('accessToken', accessToken)
        localStorage.setItem('full_access', full_access)
        if (refreshToken) {
            localStorage.setItem('refreshToken', refreshToken)
        }
        if (expiresIn) {
            localStorage.setItem(
                'expireDate',
                JSON.stringify(new Date().getTime() + expiresIn * 1000)
            )
        }
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        console.log('Logout')
        localStorage.clear()
        delete axios.defaults.headers.common.Authorization
        if (
            window.location.pathname !== '/session/signin' &&
            window.location.pathname !== '/session/signup'
        ) {
            window.location.replace('/session/signin')
        }
    }
}
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const refreshToken = async () => {
    try {
        const refreshToken = localStorage.getItem('refreshToken')
        if (!refreshToken) {
            return { success: false }
        }
        const response = await fetch(
            // `https://securetoken.googleapis.com/v1/token?key=${google_apikey}`,
            'https://api.panel.evergee.com.tr/auth/refresh',
            {
                method: 'POST',
                body: JSON.stringify({
                    grant_type: 'refresh_token',
                    refresh_token: refreshToken,
                }),
            }
        )
        const data = await response.json()
        const full_access = localStorage.getItem('full_access') === 'true'
        setSession(
            data.id_token,
            data.refresh_token,
            data.expires_in,
            full_access
        )
        await delay(100)
        return { success: true }
    } catch (error) {
        return { success: false, message: error.message }
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload
            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => {},
    register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const login = async (email, password) => {
        try {
            const response = await fetch(
                // `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${google_apikey}`,
                `https://api.panel.evergee.com.tr/auth/login`,
                {
                    method: 'post',
                    body: JSON.stringify({
                        email,
                        password,
                        returnSecureToken: true,
                    }),
                }
            )
            const {
                idToken: accessToken,
                error,
                refreshToken,
                expiresIn,
                full_access,
            } = await response.json()
            const user = {
                email,
                username: email.replace('@evergee.com.tr', ''),
            }

            setSession(accessToken, refreshToken, expiresIn, full_access)

            dispatch({
                type: 'LOGIN',
                payload: {
                    user,
                },
            })
            if (error) {
                return { success: false, error }
            }
            return { success: true }
        } catch (error) {
            alert(error.message)
            return { success: false }
        }
    }

    const register = async (email, username, password) => {
        const response = await axios.post('/api/auth/register', {
            email,
            username,
            password,
        })

        const { accessToken, user } = response.data

        setSession(accessToken)

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })
    }

    const logout = () => {
        setSession(null)
        dispatch({ type: 'LOGOUT' })
    }

    useEffect(() => {
        ;(async () => {
            try {
                let accessToken = window.localStorage.getItem('accessToken')
                let expireDate = window.localStorage.getItem('expireDate')
                if (expireDate) {
                    expireDate = JSON.parse(expireDate)
                }
                const refreshToken = window.localStorage.getItem('refreshToken')
                let gate = false
                if (
                    accessToken &&
                    refreshToken &&
                    new Date().getTime() > expireDate
                ) {
                    try {
                        const response = await fetch(
                            // `https://identitytoolkit.googleapis.com/v1/token?key=${google_apikey}`,
                            'https://api.panel.evergee.com.tr/auth/refresh',
                            {
                                method: 'post',
                                body: JSON.stringify({
                                    grant_type: 'refresh_token',
                                    refresh_token: refreshToken,
                                }),
                            }
                        )
                        const full_access =
                            localStorage.getItem('full_access') === 'true'
                        const {
                            idToken: new_accessToken,
                            error,
                            refreshToken: new_refreshToken,
                            expiresIn: new_expiresIn,
                        } = await response.json()
                        if (error) {
                            alert(error.message)
                        } else {
                            setSession(
                                new_accessToken,
                                new_refreshToken,
                                new_expiresIn,
                                full_access
                            )
                            gate = true
                        }
                    } catch (error) {
                        setSession(null)
                        alert(error.message)
                    }
                } else if (
                    accessToken &&
                    refreshToken &&
                    new Date().getTime() < expireDate
                ) {
                    gate = true
                } else {
                    setSession(null)
                }
                console.log(expireDate, new Date().getTime(), gate)
                if (gate) {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user: 'user',
                        },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
