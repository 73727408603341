import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const TDocCreator = Loadable(lazy(() => import('./tDocCreator')))
const LetterCreator = Loadable(lazy(() => import('./letterCreator')))
const ProformaCreator = Loadable(lazy(() => import('./proformaCreator')))
const PackingListCreator = Loadable(lazy(() => import('./packingListCreator')))
const SystemList = Loadable(lazy(() => import('./SystemList')))
const ClientList = Loadable(lazy(() => import('./ClientList')))
const FieldList = Loadable(lazy(() => import('./FieldList')))
const VisitList = Loadable(lazy(() => import('./VisitList')))
const ProductList = Loadable(lazy(() => import('./ProductList')))
const ClaimList = Loadable(lazy(() => import('./ClaimList')))
const ContractList = Loadable(lazy(() => import('./ContractList')))
const ReportList = Loadable(lazy(() => import('./ReportList')))
// const CostCalculation = Loadable(lazy(() => import('./costCalculation')))

const customRoutes = [
    {
        path: '/tools/tdoc-creator',
        element: <TDocCreator />,
    },
    {
        path: '/tools/letter-creator',
        element: <LetterCreator />,
    },
    {
        path: '/tools/proforma-creator',
        element: <ProformaCreator />,
    },
    {
        path: '/tools/packing-list-creator',
        element: <PackingListCreator />,
    },
    {
        path: '/service/system-list',
        element: <SystemList />,
    },
    {
        path: '/service/client-list',
        element: <ClientList />,
    },
    {
        path: '/service/field-list',
        element: <FieldList />,
    },
    {
        path: '/service/visit-list',
        element: <VisitList />,
    },
    {
        path: '/service/product-list',
        element: <ProductList />,
    },
    {
        path: '/service/claim-list',
        element: <ClaimList />,
    },
    {
        path: '/service/contract-list',
        element: <ContractList />,
    },
    {
        path: '/service/report-create',
        element: <ReportList />,
    },
]

export default customRoutes
